<template>
  <b-modal
    id="print-batch-modal"
    hide-header
    hide-footer
    centered
    size="xl"
    @hidden="clearModal"
  >
    <div class="modal-header">
      <div>
        <p class="modal-title">Folha de rosto</p>
      </div>
      <div>
        <Close class="icon" @click="clearModal" />
      </div>
    </div>
    <div class="around-content">
      <p class="orientation mb-2">
        Selecione o(s) lote(s) enviado(s) que deseja emitir a folha de rosto
      </p>
      <b-row>
        <b-col cols="4">
          <b-form-group>
            <label for="healthPlan">Convênio</label>
            <HealthPlanFilter :selectHealthPlan="handleSelectedHealthPlans" />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <label for="batch_number">Nº do lote</label>
            <b-form-input
              debounce="500"
              id="batch_number"
              v-model="filters.batchNumber"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group>
            <label for="status">Situação</label>
            <multiselect
              id="status"
              placeholder="Selecionar"
              v-model="selectedStatus"
              class="with-border"
              track-by="value"
              label="label"
              :allow-empty="false"
              :show-labels="false"
              :options="status"
              @select="handleSelectedStatus"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
            </multiselect>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <label for="period">Período</label>
            <Periods id="period" @select="handlePeriod" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="tableRows.length">
        <b-col cols="12">
          <LotsToSendTable
            :tableRows="tableRows"
            :tableHeaders="tableHeaders"
            @selected-batches="selectedBatches"
            :getTypes="getTypes"
          />
        </b-col>
        <b-pagination
          class="ml-auto"
          size="sm"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          first-number
          last-number
        />
      </b-row>

      <div class="wrapper-button">
        <b-button variant="primary" @click="printTissBatches">
          Finalizar e baixar
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { getCurrentClinic, getCurrentUser } from '@/utils/localStorageManager'
import { getTypes } from '@/utils/invoicingTissHelper'
import { tissBatchStatusOptions } from '@/modules/tiss/manageGuides/utils/statuses'

export default {
  name: 'PrintBatchModal',
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    HealthPlanFilter: () => import('@/components/Tiss/Generic/Filters/HealthPlanFilter'),
    Periods: () => import('@/components/General/Periods'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    LotsToSendTable: () => import('@/components/Tiss/Table/LotsToSendTable')
  },
  data: () => ({
    clinic: getCurrentClinic(),
    user: getCurrentUser(),
    filters: {
      healthPlan: null,
      batchNumber: null,
      status: null,
      period: {
        start: null,
        end: null
      }
    },
    selectedStatus: null,
    currentPage: 1,
    perPage: 10,
    rows: 0,
    selected: [],
    status: tissBatchStatusOptions,
    tableHeaders: [
      'checkbox',
      'Convênio',
      'N° do lote',
      'Situação',
      'Período de envio',
      'Tipo de guia'
    ],
    tableRows: []
  }),
  computed: {
    selectedIds() {
      return this.selected.map(selected => selected.id)
    }
  },
  methods: {
    getTypes,
    clearModal() {
      this.tableRows = []
      this.selectedStatus = null
      this.filters = {
        healthPlan: null,
        batchNumber: null,
        status: null,
        period: {
          start: null,
          end: null
        }
      }
      this.$bvModal.hide('print-batch-modal')
    },
    selectedBatches(value) {
      this.selected = value
    },
    isInValidFilter() {
      return Object.values(this.filters).every(
        item => !item || Object.values(item).every(item => !item)
      )
    },
    async listBatchestoPrint() {
      const isLoading = this.$loading.show()
      this.tableRows = []
      try {
        this.selected = []
        const { data } = await this.api.listBatchestoPrint(
          this.clinic.id,
          this.filters,
          this.currentPage
        )
        this.currentPage = data.current_page
        this.perPage = data.per_page
        this.rows = data.total

        this.tableRows = data.data.map(item => {
          const estimatePaymentTime =
            item?.tiss_invoice?.clinic_health_plan?.invoicing_tiss_setting
              ?.estimate_payment_time
          const sendDate = item.send_date
          return {
            id: item.id,
            Convênio:
              item?.tiss_invoice?.clinic_health_plan.health_plan?.fantasy_name,
            'N° do lote': item.number,
            Situação: item.status,
            'Período de envio': sendDate
              ? this.moment(sendDate)
                  .add(estimatePaymentTime, 'days')
                  .format('DD/MM/YYYY')
              : '-',
            'Tipo de guia': this.getTypes(item.type)
          }
        })
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async printTissBatches() {
      if (this.isInValidFilter() || !this.selectedIds.length) {
        this.$toast.warning('Selecione um lote para emitir uma folha de rosto')
        return false
      }
      try {
        const payload = {
          ids: this.selectedIds,
          professional_id: this.user.id,
          clinic_id: this.clinic.id,
          tabId: window.sessionStorage.getItem('tabId')
        }
        this.api.printTissBatches(payload)
        this.clearModal()
        this.$toast.success('Download folha(s) de rosto iniciado(s) com sucesso!')
      } catch (error) {
        this.$toast.error(error.message)  
      }
    },
    handleSelectedHealthPlans(newValue) {
      this.filters.healthPlan = newValue?.value ?? null
    },
    handlePeriod(period) {
      this.filters.period.start = period.start
      this.filters.period.end = period.end
    },
    handleSelectedStatus(option) {
      this.selectedStatus = option
      this.filters.status = option.value
    }
  },
  watch: {
    filters: {
      async handler() {
        if (this.isInValidFilter()) {
          this.tableRows = []
          return
        }
        await this.listBatchestoPrint()
      },
      deep: true
    },
      currentPage: {
        handler: function () {
          this.listBatchestoPrint()
        }
      }
  }
}
</script>

<style lang="scss">
#print-batch-modal {
  .modal-content {
    border-radius: 8px;

    .modal-body {
      padding: 0 !important;
      margin: 0 !important;

      .modal-header {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px !important;

        .modal-title {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: var(--type-active);
        }

        .icon {
          width: 25px;
          fill: var(--neutral-500);
        }
      }

      .around-content {
        padding: 24px 24px 0 24px;

        .orientation {
          color: var(--type-active);
          font-weight: 600;
          font-size: min(1.2vw, 16px);
        }
      }
      .wrapper-button {
        width: 100%;
        display: inline-flex;
        margin: 20px 0 !important;
        justify-content: flex-end;

        button {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: var(--neutral-000);
        }
      }
    }
  }
}
</style>
